import { API } from "../../constans/Url";
import { apiClient } from "../../helper";


const get_ListClient = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.CLIENT.LIST, params).then(res => {
    if (res.status == 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err =>{
    callbackError(err)
  })
}

const get_DetailClient = (id, callbackSuccess, callbackError) => {
  apiClient(true).get(API.CLIENT.GET, {id: id}).then(res => {
    if (res.status == 200){
      callbackSuccess(res.data)
    } else{ 
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_SaveClient = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.CLIENT.SAVE, params).then(res => {
    if (res.status == 200 || res.status === 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

//KATEGORI

const get_ListKategoriClient = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.CLIENT.KATEGORI.LIST, params).then(res => {
    if (res.status == 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_SaveKategoriClient = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.CLIENT.KATEGORI.SAVE, params).then(res => {
    if (res.status == 200 || res.status == 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

//CONTACT

const post_SaveContactClient = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.CLIENT.KONTAK.SAVE, params).then(res => {
    if (res.status == 200 || res.status == 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_DetailContactClient = (id, callbackSuccess, callbackError) => {
  apiClient(true).get(API.CLIENT.KONTAK.GET, {id: id}).then(res => {
    if (res.status == 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

export {
  get_ListClient,
  get_DetailClient,
  post_SaveClient,
  get_ListKategoriClient,
  post_SaveKategoriClient,
  post_SaveContactClient,
  get_DetailContactClient
}