<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="ClientModalCategory"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <form @submit.prevent="onSubmit">
          <div class="card-modal">
            <div class="modal-header">
              <div class="modal-title text-center">
                {{ labelModal }}
              </div>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label for="inputAddress2">Kategori : </label>
                <input
                  type="text"
                  class="form-control"
                  @keyup="formCheck('nama')"
                  v-model="formData.nama"
                  :class="{ 'is-invalid': formError && formError.nama }"
                  id="inputAddress2"
                  placeholder="Masukan Kategori"
                />
                <div v-if="formError && formError.nama" class="form-error">
                  {{ formError.nama }}
                </div>
              </div>
              <div class="form-group">
                <label for="inputAddress2">Status</label>
                <Select2
                  v-model="formData.status"
                  :class="{ 'is-invalid': formError && formError.status }"
                  :options="statusCompany"
                  placeholder="Status"
                  :settings="{ minimumResultsForSearch: -1 }"
                  @change="formCheck('status')"
                  @select="formCheck('status')"
                />
                <div v-if="formError && formError.status" class="form-error">
                  {{ formError.status }}
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" :disabled="isSubmit" class="btn btn-save">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex ml-3 mb-2 align-items-center">
          <div class="mr-auto">
            <ul class="nav nav-pills">
              <li class="nav-item">
                <router-link
                  to="/data-client"
                  v-if="checkAccess('client', 'cru_client')"
                  class="nav-link"
                  >Client</router-link
                >
              </li>
              <li class="nav-item">
                <router-link to="/data-client-kategori" class="nav-link active"
                  >Client Kategori
                </router-link>
              </li>
            </ul>
          </div>

          <form class="search mr-3" @submit.prevent="onSearch">
            <input
              type="text"
              v-model="search"
              @keyup="searchCheck"
              id="searchbox"
              placeholder="Cari..."
              name="search"
            />
            <button type="submit"><i class="fa fa-search"></i></button>
          </form>

          <button class="btn-add" @click="showModal">+ Tambah</button>
        </div>
        <div class="card-table">
          <div class="table-responsive">
            <table
              class="table table-bordered table-striped table-hover"
              id="tableClientCategory"
            >
              <thead>
                <tr>
                  <th style="width: 75%">Kategori</th>
                  <th style="width: 10%">Status</th>
                  <th style="width: 10%" class="text-center">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, index) in list" :key="index">
                  <td>{{ value.nama }}</td>
                  <td>
                    <div class="green-bedge" v-if="value.status === 'aktif'">
                      Aktif
                    </div>
                    <div class="red-bedge" v-else>Nonaktif</div>
                  </td>
                  <td class="text-center">
                    <svg
                      class="detail-icon"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Edit"
                      v-on:click="detail(value.id, value.nama, value.status)"
                      width="20"
                      height="20"
                      viewBox="0 0 31 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.5 25.8301H27.125"
                        stroke="#898989"
                        stroke-width="2.33333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21.3125 4.52324C21.8264 4.00938 22.5233 3.7207 23.25 3.7207C23.6098 3.7207 23.9661 3.79158 24.2986 3.92928C24.631 4.06698 24.9331 4.26881 25.1875 4.52324C25.4419 4.77768 25.6438 5.07974 25.7815 5.41217C25.9192 5.74461 25.99 6.10091 25.99 6.46074C25.99 6.82057 25.9192 7.17687 25.7815 7.50931C25.6438 7.84175 25.4419 8.14381 25.1875 8.39824L9.04167 24.5441L3.875 25.8357L5.16667 20.6691L21.3125 4.52324Z"
                        stroke="#898989"
                        stroke-width="2.33333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import {
  checkModuleAccess,
  checkRules,
  cksClient,
  showAlert,
} from "../../../helper";
import {
  get_ListKategoriClient,
  post_SaveKategoriClient,
} from "../../../actions/client";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    // TableLite
  },

  data() {
    return {
      checkAccess: checkModuleAccess,
      myValue: "aaaa",
      statusCompany: [
        { id: "aktif", text: "Aktif" },
        { id: "tidak", text: "Nonaktif" },
      ],
      list: [],
      formData: {
        id: "",
        id_company: cksClient().get("_account").id_company,
        nama: "",
        status: "aktif",
      },
      formError: [],
      rules: {
        nama: {
          required: true,
          msg: "Kategori Wajib Diisi",
        },
        status: {
          required: true,
          msg: "Status wajib diisi",
        },
      },
      isSubmit: false,
      search: "",
      isSearch: false,
      labelModal: "Tambah Kategori",
      id_company: cksClient().get("_account").id_company,
      dataTable: "",
    };
  },
  created() {
    this.getList();
  },

  beforeUnmount() {
    if (this.dataTable) {
      this.dataTable.destroy();
      this.dataTable = "";
    }
  },

  methods: {
    detail(id, nama, status) {
      this.formError = [];
      (this.labelModal = "Edit Kategori"), (this.formData.nama = nama);
      this.formData.id = id;
      this.formData.status = status;
      $("#ClientModalCategory").modal("show");
      $('[data-toggle="tooltip"]').tooltip("hide");
    },
    showModal() {
      this.formError = [];
      (this.labelModal = "Tambah Kategori"), (this.formData.nama = "");
      this.formData.id = "";
      this.formData.status = "aktif";
      $("#ClientModalCategory").modal("show");
      $('[data-toggle="tooltip"]').tooltip("hide");
    },
    async formCheck(key) {
      if (this.rules[key]) {
        this.rules[key].changed = true;
      }
      const check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },
    postData() {
      this.isSubmit = true;
      post_SaveKategoriClient(
        this.formData,
        (res) => {
          this.isSubmit = false;
          var msg =
            res.response_code === 201
              ? "Data berhasil ditambahkan"
              : "Data berhasil diperbarui";
          $("#ClientModalCategory").modal("hide");
          this.getList();

          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            showConfirmButton: true,
          });
        },
        () => {
          this.isSubmit = false;
          $("#ClientModalCategory").modal("hide");
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showConfirmButton: false,
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (this.rules[key]) {
          this.rules[key].changed = true;
        }
      }
      const check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.id
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },
    searchCheck: function () {
      if (!this.search && this.isSearch) {
        this.isSearch = false;
        this.getList();
      }
    },
    async onSearch() {
      if (this.search || this.isSearch) {
        this.isSearch = true;
        this.getList();
      }
      if (!this.search && this.isSearch) {
        this.isSearch = false;
      }
    },
    async getList() {
      if (this.dataTable) {
        this.dataTable.destroy();
      }
      await get_ListKategoriClient(
        {
          id_company: this.id_company,
          search: this.search,
        },
        (res) => {
          this.list = [];
          this.list = res.list;
          setTimeout(() => {
            $('[data-toggle="tooltip"]').tooltip();
            this.dataTable = $("#tableClientCategory").DataTable({
              info: false,
              initComplete: function () {
                $("#tableClientCategory").css("width", "");
                $("#tableClientCategory").css("min-width", "100%");
              },
            });
          }, 1000);
        }
      );
    },
  },
};
</script>

<style scoped>
button.btn-add {
  border: none !important;
}

.card-table {
  margin-top: 0px !important;
}
</style>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>
